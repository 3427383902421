import {
  AppEntityHydrated,
  AssetEntityHydrated,
  LambdaAbVariantEntity,
} from "@jackfruit/common"
import { DOMParser } from "@xmldom/xmldom"
import { trim } from "lodash"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Scroll from "react-scroll"
import { useEffectOnce, useSearchParam } from "react-use"
import { useTemplateTextFonts } from "~/hooks/useTemplateTextFonts"
import { GA4fireAbTestAttribute } from "~/services/GA4"

const isDev = process.env.GATSBY_ACTIVE_ENV === "dev"

interface Props {
  config: AppEntityHydrated
  lambdaAbExperimentId?: string
  seo?: {
    title?: string
    description?: string
    featureImage?: AssetEntityHydrated
    canonical?: string
  }
}
/**
 * Todo: Gatsby doc suggests us to use Gatsby Head api instead of Helmet
 * more info: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

const Head: React.FC<Props> = ({ config, lambdaAbExperimentId, seo }) => {
  const {
    baseUrl,
    language,
    openGraphTitle,
    openGraphDescription,
    iosStoreUrl,
    iosStoreId,
    showMobileAppInstallBanner,
    mobileAppBannerVariant,
    settings: {
      abTestAttribute,
      googleMapKey,
      mapProvider,
      lambdaAbEnabled,
      lambdaAbExperimentId: globalExperimentId,
      lambdaAbVariants,
      rawHead,
      gaProjectId,
      gaMeasurementId,
      facebookMeasurementId,
      microsoftMeasurementId,
      pinterestMeasurementId,
      tiktokMeasurementId,
    },
  } = config

  const [gaMeasurementSiteId, setGaMeasurementSiteId] = useState<string>()

  const { fonts } = useTemplateTextFonts()

  const templateFonts = fonts
    ?.filter(({ ttf }) => {
      return ttf.fontFamily !== "Poppins"
    })
    .map(
      ({ ttf, woff2 }) => `
      @font-face {
        font-family: '${ttf.fontFamily}';
        font-style: ${ttf.fontStyle};
        font-weight: ${ttf.fontWeight};
        font-display: swap; 
        src: url(${ttf.src}) format('ttf');
      }
      @font-face {
        font-family: '${woff2.fontFamily}';
        font-style: ${woff2.fontStyle};
        font-weight: ${woff2.fontWeight};
        src: url(${woff2.src}) format('woff2');
        font-display: swap; 
        unicode-range: ${woff2.unicodeRange.join(" ")};
      }
  `
    )
    ?.join("")

  const baseUrlRedirect = trim(baseUrl, "/")
  const metaTitle = seo?.title || openGraphTitle
  const metaDescription = seo?.description || openGraphDescription

  const parser = new DOMParser()
  const doc = Boolean(rawHead)
    ? parser.parseFromString(rawHead, "text/html")
    : ({} as Document)
  const rawScriptList = Boolean(rawHead)
    ? Array.from(doc.getElementsByTagName("script"))
    : []
  const rawStyle = Boolean(rawHead)
    ? doc.getElementsByTagName("style")[0]?.textContent
    : ""

  const isPreview = config.deployment?.type === "preview"
  const isTestBot = useSearchParam("test-bot") === "true"
  const experimentId = globalExperimentId || lambdaAbExperimentId

  useEffectOnce(() => {
    if (window) {
      // Bootstrap GTM
      window.dataLayer = window.dataLayer ?? []

      // Global scroller for custom
      window.scrollToElement = (elementName: string) => {
        const scroller = Scroll.scroller
        scroller.scrollTo(elementName, {
          duration: 500,
          smooth: true,
          spy: true,
        })
      }
    }

    if (isPreview) {
      setGaMeasurementSiteId(config.deployment.previewGaMeasurementId)
    } else {
      setGaMeasurementSiteId(
        isTestBot ? config.deployment.previewGaMeasurementId : gaMeasurementId
      )
    }

    if (lambdaAbEnabled && experimentId) {
      let abTestVariantId: number = NaN
      if (config.deployment) {
        abTestVariantId = (lambdaAbVariants as LambdaAbVariantEntity[])
          .sort((a, b) => (a.id as number) - (b.id as number))
          .findIndex(
            lambdaAbVariant =>
              lambdaAbVariant.id === config.deployment.lambdaAbVariantId
          )
      }

      const abCookieAttribute =
        document.cookie.match(/X-Source=(.*?)(;|$)/)?.[1]
      const abTestAttr = abCookieAttribute ?? abTestAttribute

      GA4fireAbTestAttribute(abTestAttr, experimentId, abTestVariantId)
    }
  })

  const shouldMountGtmGA4 = Boolean(gaProjectId && gaProjectId && !isDev)
  const showSmartBanner =
    mobileAppBannerVariant === "smart" && showMobileAppInstallBanner

  return (
    <Helmet>
      <html lang={language || "en-US"} translate="no" />
      <title>{metaTitle}</title>
      <meta name="viewport" content="width=device-width,user-scalable=no" />
      <meta name="name" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      {showSmartBanner && (
        <meta
          name="apple-itunes-app"
          content={`app-id=${iosStoreId},app-argument=${iosStoreUrl}`}
        />
      )}
      {seo?.featureImage?.path && (
        <meta property="og:image" content={seo.featureImage.path} />
      )}
      {seo?.featureImage?.width && (
        <meta property="og:image:width" content={`${seo.featureImage.width}`} />
      )}
      {seo?.featureImage?.height && (
        <meta
          property="og:image:height"
          content={`${seo.featureImage.height}`}
        />
      )}

      {seo?.canonical && <link rel="canonical" href={seo.canonical} />}

      <base href={!isDev ? baseUrl : "/"} />

      {gaMeasurementSiteId && (
        <script>{`
          window.dataLayer = window.dataLayer ?? []

          function gtag() {
            dataLayer.push(arguments);
          }
          dataLayer.push({
            GTM_VAR_GA4_MEASUREMENT_ID: "${gaMeasurementSiteId}",
            FB_VAR_MEASUREMENT_ID: "${facebookMeasurementId}",
            MICROSOFT_VAR_MEASUREMENT_ID: "${microsoftMeasurementId}",
            PIN_VAR_MEASUREMENT_ID: "${pinterestMeasurementId}",
            TIKTOK_VAR_MEASUREMENT_ID: "${tiktokMeasurementId}",
          })
        `}</script>
      )}

      {shouldMountGtmGA4 && (
        <script
          async
        >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://tagging.autopilotprint.xyz/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gaProjectId}');`}</script>
      )}

      <script>{`
          if (
            window.location.href.indexOf("${baseUrlRedirect}") !== 0 &&
            window.location.href.indexOf("localhost") === -1
          ) {
            window.location.href = "${baseUrlRedirect}" + window.location.search
          }
        `}</script>

      {Boolean(templateFonts) && <style>{templateFonts}</style>}

      {mapProvider === "googleMaps" && googleMapKey && (
        <script
          async
          type="text/javascript"
          src={`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&libraries=places,visualization`}
        />
      )}
      {Boolean(rawStyle) && <style type="text/css">{rawStyle}</style>}
      {rawScriptList.length > 0 &&
        rawScriptList?.map((raw, index) => {
          const srcUrl = raw.getAttribute("src") as string
          return srcUrl ? (
            <script key={index} src={srcUrl} async></script> //Support CDN
          ) : (
            <script key={index}>{raw.textContent}</script>
          )
        })}
    </Helmet>
  )
}

export default Head
